import { routeEnums } from "../enums/routeEnums"


export const mappedRoutes = [
   {
      paramName: '',
      routeName: routeEnums.DASHBOARD
   },
   {
      paramName: 'dash',
      productName: 'Dashboard',
      routeName: routeEnums.DASHBOARD
   },
   {
      paramName: 'upload',
      productName: 'Upload-Document',
      routeName: routeEnums.DocumentUpload
   },
   {
      paramName: 'plts',
      productName: 'Plutos',
      routeName: routeEnums.PLUTOS
   },
   {
      paramName: 'hap',
      productName: 'Health Advantedge Plus',
      routeName: routeEnums.OrionHap
   },
   {
      paramName: 'elevate',
      productName: 'Elevate',
      routeName: routeEnums.Elevate
   },
   {
      paramName: 'elevate-orion',
      productName: 'Elevate Orion',
      routeName: routeEnums.OrionElevate
   },
   {
      paramName: 'activate-booster',
      productName: 'Activate Booster',
      routeName: routeEnums.ActivateBooster
   },
   {
      paramName: 'activate-booster-combo',
      productName: 'Super Saver',
      routeName: routeEnums.SuperSaver
   },
   {
      paramName: 'calculate-elevate',
      productName: 'Calculate-Elevate',
      routeName: routeEnums.CalculateEevate
   },
   // {
   //    paramName: 'orion-calculate-elevate',
   //    productName: 'Orion-Calculate-Elevate',
   //    routeName: routeEnums.OrionCalculateEevate
   // },
   {
      paramName: 'calculate-activate-booster-combo',
      productName: 'Calculate Super Saver',
      routeName: routeEnums.CalculateSuperSaver
   },
   {
      paramName: 'calculate-activate-booster',
      productName: 'Calculate Activate Booster',
      routeName: routeEnums.CalculateActivateBooster
   },
   {
      paramName: 'gs',
      productName: 'Golden Shield',
      routeName: routeEnums.GoldenShield
   },
   {
      paramName: 'asp',
      productName: 'Arogya Sanjeevani',
      routeName: routeEnums.ASP
   },
   {
      paramName: 'hep',
      productName: 'Health Elite Plus',
      routeName: routeEnums.HealthElitePlus
   },
   {
      paramName: 'max',
      productName: 'Max Protect',
      routeName: routeEnums.MaxProtect
   },
   {
      paramName: 'hb',
      productName: 'Health Booster',
      routeName: routeEnums.HEALTH_BOOSTER
   },
   {
      paramName: 'cs',
      productName: 'Criti Shield',
      routeName: routeEnums.CritiShield
   },
   {
      paramName: 'fs',
      productName: 'Criti Shield',
      routeName: routeEnums.Familyshield
   },
   {
      paramName: 'renewalDue',
      productName: 'Renewal Due',
      routeName: routeEnums.Renewals
   },
   {
      paramName: 'instantRenewal',
      productName: 'Instant Renewal',
      routeName: routeEnums.InstantRenewal
   },
   {
      paramName: 'savedQuotes',
      productName: 'Saved Quotes',
      routeName: routeEnums.SavedQuotes
   },
   {
      paramName: 'student',
      productName: 'Student Travel',
      routeName: routeEnums.STUDENT_MEDICAL_TRAVEL
   },
   {
      paramName: 'international',
      productName: 'International Travel',
      routeName: routeEnums.INTERNATIONAL_TRAVEL
   }
]

export const renewalMockData = [
   {
      "Product_Name": 'Complete Health Policy',
      "Cart_Type":"Current_Plan",
      "SI_Discription": 'Renew your Complete Health Insurance plan for a seamless and comprehensive coverage',
      "SubSequentSIPremiums": [
         {
            "SumInsured": 10000.0,
            "Selected":false,
            "PremiumDetails": [
               {
                  "BasicPremium": 9203.0,
                  "TotalTax": 1657.0,
                  "TotalPremium": 10860.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "10000",
                  "Tenure": 1,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 17650.0,
                  "TotalTax": 3177.0,
                  "TotalPremium": 20827.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "10000",
                  "Tenure": 2,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 25787.0,
                  "TotalTax": 4642.0,
                  "TotalPremium": 30429.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "10000",
                  "Tenure": 3,
                  "InstallmentEMI": null
               }
            ],
            "ErrorText": null
         },
      ],
   },
   {
      "Product_Name": 'Health Adventage Plus',
      "SI_Discription": "review you complete health inusrance plan with higher sum insured to meet today's medical treatment expenses. ",
      "Cart_Type":"Recommanded_Plan",
      "SubSequentSIPremiums": [
         {
            "SumInsured": 1000000,
            "Selected":true,
            "PremiumDetails": [
               {
                  "BasicPremium": 9203.0,
                  "TotalTax": 1657.0,
                  "TotalPremium": 10860.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1000000",
                  "Tenure": 1,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 17650.0,
                  "TotalTax": 3177.0,
                  "TotalPremium": 20827.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1000000",
                  "Tenure": 2,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 25787.0,
                  "TotalTax": 4642.0,
                  "TotalPremium": 30429.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1000000",
                  "Tenure": 3,
                  "InstallmentEMI": null
               }
            ],
            "ErrorText": null
         },
         {
            "SumInsured": 1500000.0,
            "Selected":false,
            "PremiumDetails": [
               {
                  "BasicPremium": 11073.0,
                  "TotalTax": 1993.0,
                  "TotalPremium": 13067.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1500000",
                  "Tenure": 1,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 21219.0,
                  "TotalTax": 3819.0,
                  "TotalPremium": 25038.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1500000",
                  "Tenure": 2,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 30972.0,
                  "TotalTax": 5575.0,
                  "TotalPremium": 36547.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "1500000",
                  "Tenure": 3,
                  "InstallmentEMI": null
               }
            ],
            "ErrorText": null
         },
         {
            "SumInsured": 2000000.0,
            "Selected":false,
            "PremiumDetails": [
               {
                  "BasicPremium": 12205.0,
                  "TotalTax": 2197.0,
                  "TotalPremium": 14402.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "2000000",
                  "Tenure": 1,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 23376.0,
                  "TotalTax": 4208.0,
                  "TotalPremium": 27584.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "2000000",
                  "Tenure": 2,
                  "InstallmentEMI": null
               },
               {
                  "BasicPremium": 34105.0,
                  "TotalTax": 6139.0,
                  "TotalPremium": 40244.0,
                  "PolicyStatus": "APPROVE",
                  "SumInsured": "2000000",
                  "Tenure": 3,
                  "InstallmentEMI": null
               }
            ],
            "ErrorText": null
         }
      ],
   }
]